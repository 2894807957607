import React from "react"
import theme from "theme"
import {
  Theme,
  Text,
  Box,
  Image,
  LinkBox,
  Section,
  Button,
} from "@quarkly/widgets"
import { Helmet } from "react-helmet"
import { GlobalQuarklyPageStyles } from "global-page-styles"
import { RawHtml, Override } from "@quarkly/components"
import * as Components from "components"
export default () => {
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"about"} />
      <Helmet>
        <title>About Us | Discover Legal Pathways Firm</title>
        <meta
          name={"description"}
          content={"Where Every Case is a Journey Worth Navigating"}
        />
        <meta
          property={"og:title"}
          content={"About Us | Discover Legal Pathways Firm"}
        />
        <meta
          property={"og:description"}
          content={"Where Every Case is a Journey Worth Navigating"}
        />
        <meta
          property={"og:image"}
          content={"https://fivaradrak.com/img/1.jpg"}
        />
        <link
          rel={"shortcut icon"}
          href={"https://fivaradrak.com/img/note-icon.png"}
          type={"image/x-icon"}
        />
        <link
          rel={"apple-touch-icon"}
          href={"https://fivaradrak.com/img/note-icon.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"76x76"}
          href={"https://fivaradrak.com/img/note-icon.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"152x152"}
          href={"https://fivaradrak.com/img/note-icon.png"}
        />
        <link
          rel={"apple-touch-startup-image"}
          href={"https://fivaradrak.com/img/note-icon.png"}
        />
        <meta
          name={"msapplication-TileImage"}
          content={"https://fivaradrak.com/img/note-icon.png"}
        />
        <meta
          name={"msapplication-TileColor"}
          content={"https://fivaradrak.com/img/note-icon.png"}
        />
      </Helmet>
      <Components.Header />
      <Section
        padding="80px 0 80px 0"
        lg-padding="56px 0 56px 0"
        sm-padding="32px 0 32px 0"
        background="--color-light"
      >
        <Box
          margin="0px 0px 32px 0px"
          md-margin="0px 0px 40px 0px"
          lg-margin="0px 0px 33px 0px"
          display="flex"
          flex-direction="column"
          align-items="center"
          lg-padding="15px 15px 15px 15px"
          sm-margin="0px 0px 10px 0px"
          justify-content="center"
        >
          <Text
            font="--headline1"
            color="--dark"
            text-align="center"
            md-font='normal 700 42px/1.2 "Source Sans Pro", sans-serif'
            margin="16px 0px 0px 0px"
          >
            About Us
          </Text>
          <Text
            font="--lead"
            color="--darkL2"
            text-align="left"
            md-width="100%"
            max-width="1000px"
          >
            Welcome to the heart of Legal Pathways Firm. Our journey began with
            a simple mission: to demystify the legal process for everyone who
            finds themselves navigating the often complex legal world. We've
            built our foundation on the principles of integrity, transparency,
            and unwavering support for our clients. Our goal is to empower you
            with the knowledge and guidance needed to make informed decisions
            throughout your legal journey.
          </Text>
        </Box>
        <LinkBox
          display="flex"
          md-flex-direction="column"
          md-align-items="center"
          md-justify-content="center"
          flex-wrap="wrap"
          width="100%"
          margin="0px 0px 16px 0px"
          justify-content="flex-start"
          md-margin="0px 0px 16px 0px"
        >
          <Box
            width="100%"
            align-items="flex-start"
            display="flex"
            justify-content="flex-start"
            md-width="100%"
            lg-width="100%"
          >
            <Image
              src="https://fivaradrak.com/img/6.jpg"
              border-radius="24px"
              max-width="100%"
              max-height="522px"
              width="100%"
              object-fit="cover"
              lg-max-height="392px"
              object-position="0% 30%"
              sm-max-height="213px"
            />
          </Box>
          <Box
            width="100%"
            display="flex"
            align-items="flex-start"
            justify-content="flex-start"
            md-border-width="0px"
            flex-direction="column"
            padding="16px 12px 16px 12px"
          >
            <Text font="--headline3" color="--dark" margin="0px 0px 8px 0px">
              Our Expertise
            </Text>
            <Text margin="0px 0px 0px 0px" font="--lead" color="--grey">
              At Legal Pathways Firm, we boast a diverse team of legal
              professionals, each bringing their own unique expertise and
              passion for justice. Our team's wide-ranging specializations mean
              we are equipped to tackle a variety of legal challenges, from the
              straightforward to the most complex. This diversity not only
              enriches our firm but ensures that whatever your legal needs, we
              have the expertise to guide you.
            </Text>
          </Box>
        </LinkBox>
        <LinkBox
          display="flex"
          md-flex-direction="column"
          md-align-items="center"
          md-justify-content="center"
          flex-wrap="wrap"
          width="100%"
          margin="0px 0px 16px 0px"
          justify-content="flex-start"
          md-margin="0px 0px 16px 0px"
        >
          <Box
            width="100%"
            align-items="flex-start"
            display="flex"
            justify-content="flex-start"
            md-width="100%"
            lg-width="100%"
          >
            <Image
              src="https://fivaradrak.com/img/7.jpg"
              border-radius="24px"
              max-width="100%"
              max-height="522px"
              width="100%"
              object-fit="cover"
              lg-max-height="392px"
              object-position="0% 30%"
              sm-max-height="213px"
            />
          </Box>
          <Box
            width="100%"
            display="flex"
            align-items="flex-start"
            justify-content="flex-start"
            md-border-width="0px"
            flex-direction="column"
            padding="16px 12px 16px 12px"
          >
            <Text font="--headline3" color="--dark" margin="0px 0px 8px 0px">
              What Makes Us Stand Out
            </Text>
            <Text margin="0px 0px 0px 0px" font="--lead" color="--grey">
              Client-Centered Approach: We prioritize your needs and goals,
              offering personalized guidance every step of the way.
              <br />
              <br />
              Innovative Solutions: Leveraging the latest legal technologies and
              strategies to offer efficient and effective outcomes.
              <br />
              <br />
              Education and Empowerment: We believe in educating our clients,
              ensuring you understand your rights and options.
              <br />
              <br />
              Commitment to Excellence: Our relentless pursuit of justice and
              excellence in all we do sets us apart.
            </Text>
          </Box>
        </LinkBox>
      </Section>
      <Section
        padding="60px 0 60px 0"
        sm-padding="40px 0"
        min-height="300px"
        sm-min-height="auto"
        background="--color-light"
        display="flex"
        flex-direction="column"
      >
        <Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
        <Text
          font="--base"
          text-transform="uppercase"
          letter-spacing="1px"
          color="--grey"
          margin="0px 0px 8px 0px"
          width="100%"
        >
		Embark on Your Legal Journey with Confidence
        </Text>
        <Text
          as="h2"
          margin="0px"
          font="--headline2"
          md-font="--headline2"
          color="--dark"
          width="40%"
          padding="0px 16px 0px 0px"
          md-width="100%"
          md-margin="0px 0px 32px 0px"
          md-padding="0px 0px 0px 0px"
        >
          Craft Your Path to Resolution
        </Text>
        <Text
          margin="0px"
          font="--base"
          color="--grey"
          width="40%"
          padding="0px 16px 0px 16px"
          md-width="50%"
          md-padding="0px 16px 0px 0px"
          sm-width="100%"
          sm-margin="0px 0px 16px 0px"
          sm-padding="0px 0px 0px 0px"
        >
          At Legal Pathways Firm, we invite you to become an integral part of our story. Together, we can navigate the complexities of the legal system, crafting a path towards resolution and peace of mind. With our team by your side, you're not just a client – you're a partner in the journey to justice.
        </Text>
        <Button
          margin="0px"
          font="--base"
          color="--dark"
          width="20%"
          padding="0px 16px 0px 16px"
          md-width="50%"
          md-padding="0px 0px 0px 16px"
          sm-width="100%"
          sm-padding="0px 0px 0px 0px"
          sm-margin="0px 0px 32px 0px"
          type="link"
          href="/contacts"
          text-align="center"
          align-self="auto"
          display="flex"
          justify-content="center"
          text-decoration-line="underline"
          align-items="center"
          border-radius="8px"
          background="rgba(0, 0, 0, 0)"
        >
          Get in Touch
        </Button>
      </Section>
      <Components.Footer />
      <RawHtml>
        <style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  )
}
